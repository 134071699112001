<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="工资月份" prop="salaryDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="queryParam.salaryDate"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="姓名" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="银行卡号" prop="bankCardNumber">
                  <a-input v-model="queryParam.bankCardNumber" placeholder="请输入银行卡号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="身份证" prop="identityCard">
                  <a-input v-model="queryParam.identityCard" placeholder="请输入身份证" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:salaryRecord:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tenant:iot:salaryRecord:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:salaryRecord:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="danger" @click="handleBatchDelete" v-hasPermi="['tenant:iot:salaryRecord:remove']">
            <a-icon type="delete" />进入批量删除
          </a-button>
          <a-button
            type="primary"
            @click="$refs.importExcel.importExcelHandleOpen()"
            v-hasPermi="['tenant:iot:salaryRecord:import']"
          >
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:salaryRecord:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :sexOptions="sexOptions" :statusOptions="statusOptions" @ok="getList" />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table tid="1" :loading="loading" :size="tableSize" :columns="columns" :data-source="list" :pagination="false">
        <span slot="sex" slot-scope="text, record">
          {{ sexFormat(record) }}
        </span>
        <span slot="workDate" slot-scope="text, record">
          {{ parseDateTime(record.workDate, 'yyyy-MM-dd') }}
        </span>
        <span slot="salaryDate" slot-scope="text, record">
          {{ parseDateTime(record.salaryDate, 'yyyy-MM') }}
        </span>
        <span slot="status" slot-scope="text, record">
          {{ statusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:salaryRecord:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:salaryRecord:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:salaryRecord:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:salaryRecord:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <a-modal
        title="批量删除"
        :visible="batchDeleteVisible"
        :confirm-loading="loading"
        @ok="handleBatchDeleteOk"
        @cancel="handleBatchDeleteCancel"
      >
        <a-form-model-item label="工资月份">
          <a-date-picker v-model="salaryDate" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM" allow-clear />
        </a-form-model-item>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listSalaryRecord, delSalaryRecord, exportSalaryRecord, removeBySalaryDate } from '@/api/iot/salaryRecord'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'SalaryRecord',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 性别字典
      sexOptions: [],
      // 参加工作时间时间范围
      daterangeWorkDate: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        identityCard: null,
        name: null,
        bankCardNumber: null,
        workDate: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '所属机构',
          dataIndex: 'deptName',
          scopedSlots: { customRender: 'deptName' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '工号',
          dataIndex: 'jobNumber',
          width: '16%',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: '16%',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '基本工资',
          dataIndex: 'basePay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '应发合计',
          dataIndex: 'totalPayable',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '实发工资',
          dataIndex: 'netPayment',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '工资月份',
          dataIndex: 'salaryDate',
          scopedSlots: { customRender: 'salaryDate' },
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ],
      batchDeleteVisible: false,
      salaryDate: null
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('sys_user_sex').then((response) => {
      this.sexOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询薪资发放记录列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.daterangeWorkDate !== null && this.daterangeWorkDate !== '' && this.daterangeWorkDate.length !== 0) {
        this.queryParam.params['beginWorkDate'] = this.daterangeWorkDate[0]
        this.queryParam.params['endWorkDate'] = this.daterangeWorkDate[1]
      }
      listSalaryRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 性别字典翻译
    sexFormat(row, column) {
      return this.selectDictLabel(this.sexOptions, row.sex)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeWorkDate = []
      this.queryParam = {
        identityCard: undefined,
        name: undefined,
        bankCardNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delSalaryRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportSalaryRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleBatchDelete() {
      console.log('handleBatchDelete')
      this.batchDeleteVisible = true
    },
    handleBatchDeleteOk() {
      const params = {
        salaryDate: this.salaryDate
      }
      removeBySalaryDate(params).then((response) => {
        this.$message.success('已删除' + this.salaryDate.substring(0, 7) + '月份共' + response.count + '条记录', 3)
        this.batchDeleteVisible = false
        this.getList()
      })
    },
    handleBatchDeleteCancel() {
      this.batchDeleteVisible = false
    }
  }
}
</script>
